@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: local("Open Sans ExtraBold"), local("Open-Sans-ExtraBold"), url("../../fonts/OpenSans-ExtraBold.woff2") format("woff2"), url("../../fonts/OpenSans-ExtraBold.woff") format("woff"); }

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  src: local("Open Sans ExtraBoldItalic"), local("Open-Sans-ExtraBoldItalic"), url("../../fonts/OpenSans-ExtraBoldItalic.woff2") format("woff2"), url("../../fonts/OpenSans-ExtraBoldItalic.woff") format("woff"); }

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"), local("Open-Sans-Bold"), url("../../fonts/OpenSans-Bold.woff2") format("woff2"), url("../../fonts/OpenSans-Bold.woff") format("woff"); }

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: local("Open Sans BoldItalic"), local("Open-Sans-BoldItalic"), url("../../fonts/OpenSans-BoldItalic.woff2") format("woff2"), url("../../fonts/OpenSans-BoldItalic.woff") format("woff"); }

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"), local("Open-Sans-SemiBold"), url("../../fonts/OpenSans-SemiBold.woff2") format("woff2"), url("../../fonts/OpenSans-SemiBold.woff") format("woff"); }

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: local("Open Sans SemiBoldItalic"), local("Open-Sans-SemiBoldItalic"), url("../../fonts/OpenSans-SemiBoldItalic.woff2") format("woff2"), url("../../fonts/OpenSans-SemiBoldItalic.woff") format("woff"); }

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("Open-Sans-Regular"), url("../../fonts/OpenSans-Regular.woff2") format("woff2"), url("../../fonts/OpenSans-Regular.woff") format("woff"); }

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: local("Open Sans Italic"), local("Open-Sans-Italic"), url("../../fonts/OpenSans-Italic.woff2") format("woff2"), url("../../fonts/OpenSans-Italic.woff") format("woff"); }

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("Open-Sans-Light"), url("../../fonts/OpenSans-Light.woff2") format("woff2"), url("../../fonts/OpenSans-Light.woff") format("woff"); }

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: local("Open Sans LightItalic"), local("Open-Sans-LightItalic"), url("../../fonts/OpenSans-LightItalic.woff2") format("woff2"), url("../../fonts/OpenSans-LightItalic.woff") format("woff"); }
